import Vue from "vue";

export default new (class SalesrepService {


  async GetSalesrepAreas(countries, salesreps){
    const path = `/api/salesreps/SalesrepAreasFeatureCollection?countries=${countries}&salesreps=${salesreps}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        console.log(error)
      });

    return result;
  }

  async GetSalesrepById(id) {
    const path = `/api/salesreps/${id}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async CreateEngagement(lifecycleType, organizationId, message) {
    const path = `/api/salesreps/CreateEngagement/${lifecycleType}/${organizationId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post",
      data: {message: message}
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async DisableEngagement(lifecycleType, organizationId) {
    const path = `/api/salesreps/DisableEngagement/${lifecycleType}/${organizationId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "post"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }
  
  async GetOrganizationEngagementHistory(lifecycleType, organizationId){
    const path = `/api/salesreps/OrganizationEngagementHistory/${lifecycleType}/${organizationId}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }


  async GetSalesreps() {
    const path = `/api/salesreps`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetCustomersPaginated(salesrepId, engaged, pageNumber, pageSize, search) {
    const path = `/api/salesreps/CustomersPaginated/${salesrepId}?engaged=${engaged}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetProspectsPaginated(salesrepId, engaged, pageNumber, pageSize, search) {
    const path = `/api/salesreps/ProspectsPaginated/${salesrepId}?engaged=${engaged}&pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

    return result;
  }

  async GetExcel(id, lifecycleType) {
    const path = `/api/salesreps/GetExcel/${id}?lifecycleType=${lifecycleType}`;
    let result = null;
    await Vue.prototype.$axios({
      url: path,
      method: "get",
      responseType: "blob"
    })
      .then((response) => {
        result = response.data;
      })
      .catch((error) => {
        result = error.response.data
      });

      
    return result;
  }  
  

})();
