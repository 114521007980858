<template>
<v-container v-if="show">
    <v-row>
        <v-checkbox :disabled="loading"  color="primary" v-model="showAreas" @change="plotSalesrepArea" label="Plot areas" ></v-checkbox>
    </v-row>
    <v-row>
    </v-row>
</v-container>
</template>

<script>

import salesrepService from "@/services/SalesrepService"


export default {
  props:{
    show: {type: Boolean, default:false}
  },
    components:{
    },
  data() {
    return {
      dialog: false,
      loading: false,
      showAreas: false,
      open:false
    };
  },
  watch:{
      async values(newValue){
          await this.plotSalesrepArea(newValue.length > 0 && this.showAreas)
      }
  },
computed:{
      values(){
        let values =  this.$store.getters["filter/filterRequest"].salesreps
        return values;
      }
    },
  methods: {
    async plotSalesrepArea(val) {
        
        if(!val){
            Window.postalCodeFeatureGroup.clearLayers();
            return;
        }
    
      this.loading = true;
      Window.postalCodeFeatureGroup.clearLayers();
      this.$store.dispatch("util/setLoading", {
        visible: true,
        text: "Fetching sales rep areas",
      });
      let postalcodes = await salesrepService.GetSalesrepAreas(["SE", "NO"], this.values);
    
      if (postalcodes == null) {
        const snackbar = {
          show: true,
          message: "",
          errors: ["Could not load polygons"],
        };
        this.$store.dispatch("util/setSnackbar", snackbar);
        this.$store.dispatch("util/resetLoading");
        this.loading = false;

        return;
      }

      postalcodes.forEach((i) => {
        let fc = JSON.parse(i.geoJson);
        window.L.geoJSON(fc, {
          onEachFeature: (feature, layer) => {
            layer.setStyle({
              fillColor: i.color,
              color: i.color,
              weight: 1,
              fillOpacity: 0.8,
            });

            layer.on("contextmenu", async (e) => {
              window.L.popup({ minWidth: "100" })
                .setLatLng(e.latlng)
                .setContent(`<p>${i.postalcode} -- ${i.salesrep}</p>`)
                .openOn(Window.leafletmap);
            });

            Window.postalCodeFeatureGroup.addLayer(layer);
          },
        });
      });
      this.loading = false;
      this.$store.dispatch("util/resetLoading");
    },
  },
};
</script>