<template>
  <v-container>
    <v-row>
      <v-autocomplete
        :value="values"
        label="Salesrep"
        :items="items"
        clearable
        @input="set"
        item-text="name"
        item-value="id"
        :multiple="multiple"
      >
      <template v-slot:selection="{ item, index }">
        <v-chip small v-if="index === 0">
          <span>{{ item.name }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text caption">(+{{ values.length - 1 }})</span>
      </template>  
      <template v-slot:append-outer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-icon color="primary" @click="selectAll">mdi-select-all</v-icon>
        </v-slide-x-reverse-transition>
      </template>              
      </v-autocomplete>
    </v-row>
    <v-row >
      <salesrepareamap :show="showPlotAreaFunction" />
    </v-row>
  </v-container>
</template>

<script>
import salesrepService from "@/services/SalesrepService";
import salesrepareamap from "@/components/salesrep/SalesrepAreaMap";

export default {
  props: {
    multiple: { default: true, type: Boolean },
    showPlotAreaFunction: { default: false, type: Boolean }
  },
  components: {
    salesrepareamap,
  },
  data: () => ({
    items: [],
    countries: ["NO", "SE"],
  }),
  computed: {
    values() {
      let values = this.$store.getters["filter/filterRequest"].salesreps;
      return this.multiple ? values : values[0];
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.items = await salesrepService.GetSalesreps();
    },
    selectAll(){
      let values = this.items.map(i => i.id);
      this.$store.dispatch("filter/setSalesreps", values);
    },    
    filterByCountry(countries) {
      alert(countries);
    },
    set(val) {
      let payload = this.multiple ? val : [val];
      this.$store.dispatch("filter/setSalesreps", payload);
    },
  },
};
</script>